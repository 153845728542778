<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAdd" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Add Country</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Coutry Name"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  rounded
                  :counter="100"
                  v-model="name"
                  :error-messages="errMsg"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Country Code"
                  required
                  outlined
                  hide-details="auto"
                  dense
                  rounded
                  v-model="code"
                  :error-messages="codeErr"
                  @input="$v.code.$touch()"
                  @blur="$v.code.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="createArea">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "addCountry",
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      maxLength: maxLength(100)
    },
    code: {
      required
    }
  },
  props: ["dialogAdd"],

  computed: {
    errMsg() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Nama jurusan maksimal 100 karakter!");
      !this.$v.name.required && errors.push("Nama Negara harus di isi!");
      return errors;
    },
    codeErr() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      !this.$v.code.required && errors.push("Kode Negara harus di isi!");
      return errors;
    }
  },
  data() {
    return {
      name: "",
      code: ""
    };
  },

  methods: {
    createArea() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v.$invalid);
        return;
      } else {
        this.$store
          .dispatch("area/createCountry", {
            nama_negara: this.name,
            kode_negara: this.code
          })
          .then(data => {
            if (data.data.status == "success") {
              this.name = "";
              this.code = "";
              this.$v.$reset();
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: data.data.message,
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            } else {
              console.log(data);
              Swal.fire({
                icon: "error",
                title: data.data.data.errors.nama[0],
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            }
          });
      }
    }
  }
};
</script>

<style></style>
