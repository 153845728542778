<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card
        class="radius-card _60-w pb-3"
        :loading="loading"
        min-height="150px"
      >
        <v-toolbar flat dense> Country </v-toolbar>
        <v-card-title class="pa-3">
          <v-btn outlined rounded color="blue" @click="dialogAdd = true"
            ><v-icon>mdi-plus</v-icon> Add Country</v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            dense
            single-line
            solo
            @keyup="fetchItems"
            rounded
            hide-details
          ></v-text-field>
        </v-card-title>
        <AddCountry @false="closeDialog" v-bind:dialogAdd="dialogAdd" />

        <div class="mt-10" v-if="country">
          <v-data-table
            :headers="headers"
            hide-default-footer
            :page.sync="page"
            :items-per-page="perPage"
            :items="country.data"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="cyan"
                  class="mr-2"
                  @click.prevent="updateItem(item)"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  @click.prevent="deleteItem(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pa-3 mb-3">
            <v-pagination
              v-model="page"
              circle
              @input="fetchItems"
              :length="country.meta.last_page"
            ></v-pagination>
          </div>
          <UpdateCountry
            @false="closeDialog"
            v-bind:dialogUpdate="dialogUpdate"
            v-bind:item="item"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import AddCountry from "../../components/Admin/Modal/addCountry.vue";
import UpdateCountry from "../../components/Admin/Modal/updateCountry.vue";
import { log } from "console";

export default {
  name: "CountryManagement",

  components: {
    AddCountry,
    UpdateCountry,
  },

  computed: {
    ...mapState({
      country: (state) => state.area.country,
    }),
  },

  data() {
    return {
      dialogAdd: false,
      dialogUpdate: false,
      item: null,
      search: "",
      page: 1,
      perPage: 5,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "nama_negara" },
        { text: "CODE", value: "kode_negara" },
        { text: "ACTIONS", value: "actions" },
      ],
    };
  },

  mounted() {
    this.fetchItems(); //get all data when load page
  },

  methods: {
    fetchItems() {
      let data = {
        page: this.page,
        limit: this.perPage,
        find: this.search,
      };
      //get all data  fucntion
      this.loading = true;
      this.$store.dispatch("area/listCountry", data).then((data) => {
        console.log(data);
        this.loading = false;
      });
    },

    updateItem(item) {
      this.item = item;
      this.dialogUpdate = true;
    },

    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store
            .dispatch("area/deleteCountry", { id: item.id })
            .then(() => {
              this.fetchItems(); //if success refetch data all
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true,
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.dialogAdd = false; //set variable dialog to false
      this.dialogUpdate = false; //set variable dialog to false
      this.fetchItems(); //and refetch data
    },
  },
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
